<template>
  <v-container :fluid="true" class="synapsa-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
          v-for="tab in tabs"
          v-permission="tab.permissions"
          :key="tab.id"
          :text="!tab.ids.includes(activeTab)"
          :color="tab.ids.includes(activeTab) ? 'primary' : ''"
          :outlined="tab.ids.includes(activeTab)"
          small
          class="text-capitalize synapsa-tab"
          :class="tab.ids.includes(activeTab) ? 'elevation-1' : ''"
          @click="to(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
export default {
  directives: { permission },
  data() {
    return {
      activeTab: "default",
      tabs: [
        {
          id: "default",
          ids: ["default"],
          name: "interconnector.rules.default",
          route: "/interconnector/rules/default",
          permissions: ["view rules"],
        },
        {
          id: "script",
          ids: ["script"],
          name: "interconnector.rules.script",
          route: "/interconnector/rules/script",
          permissions: ["view rules"],
        },
      ],
    };
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
  },
};
</script>
